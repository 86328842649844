@import "../../../Common/styles/componentes.scss";


.input-search{
    width: 400px;
    height: 38px;
    box-shadow: 0 1px 0 0 #afafaf;
    background-color: #edf2f7;
    border: none;
    border-left: 12px solid #53a9ff;
    padding-left: 15px;
    padding-right: 42px;
    font-weight: 400;

    @extend .text-black-14;

    &.full{
      background-color: white; 
    }

    &::placeholder {
      color: #afb1bc;
      font-size: 13.5px;
    }

    &:hover{
      border-style: solid;
      border-color: #afb1bc;
      border-width: 1px 1px 0px 1px;
      border-left: 12px solid #53a9ff;
    }

    &:focus{
      border-left: 12px solid #53a9ff!important;
      background-color: white;

      &::placeholder {
        color: transparent;
        font-weight: 300;
      }
    }
}

.input-search-lupe {
  font-size: 12px;
  position: relative;
  right: 32px;
}

.data-item{
    &:hover{
        background-color: #f1f4f7;
        font-weight: 500;
    }
}